@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@500;700&family=Roboto+Mono:wght@500&display=swap");
html,
body {
  --custom-font-family: "Nunito", sans-serif;
  --custom-bg-color: #101010;
  --custom-panel-color: #222;
  --custom-box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.8);
  --custom-color: #fff;
  --custom-color-brand: #24b47e;
  --custom-color-other: #dfb5f0;
  --custom-color-limit: #6cd0ff;
  --custom-color-secondary: #666;
  --custom-border: 1px solid #333;
  --custom-border-radius: 5px;
  --custom-spacing: 5px;
  --max-width-media-screen: 500px;

  padding: 0;
  margin: 0;
  font-family: var(--custom-font-family);
  background-color: var(--custom-bg-color);
}

* {
  color: var(--custom-color);
  font-family: var(--custom-font-family);
  box-sizing: border-box;
}

html,
body,
#root,
#__next {
  height: 100vh;
  width: 100vw;
  overflow-x: hidden;
}

/* Login */

.sign-up-box {
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
}

.sign-up-alert {
  color: red;
  font-size: 1.5rem;
  padding: 5px;
}

.sign-up-success {
  color: var(--custom-color-brand);
  font-size: 1.5rem;
  padding: 5px;
}

.sign-up-input {
  padding: 10px;
  margin: 5px;
  font-size: 2rem;
  color: var(--custom-color-brand);
  border: var(--custom-border);
  background-color: var(--custom-bg-color);
  display: inline-block;
  text-align: center;
  border-radius: var(--custom-border-radius);
  padding: 2rem;
  cursor: pointer;
  text-align: center;
}

@media screen and (max-width: 500px) {

  .friends-title {
    margin-bottom: 5px;
  }
  .sign-up-input {
    width: 80%;
  }

  .sign-up-form {
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;
  }
}

/* Button */

button,
.button {
  color: var(--custom-color);
  border: var(--custom-border);
  background-color: var(--custom-bg-color);
  display: inline-block;
  text-align: center;
  border-radius: var(--custom-border-radius);
  padding: 1.5rem 2rem;
  cursor: pointer;
  text-align: center;
  font-size: 0.9rem;
  text-transform: uppercase;
}

button.primary,
.button.primary {
  background-color: var(--custom-color-brand);
  border: 1px solid var(--custom-color-brand);
  font-size: 2rem;
  margin-bottom: 1rem;
}

/* Grid */

.auth-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.container {
  flex: 1;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  background-color: var(--custom-bg-color);
}
.row {
  position: relative;
  width: 100%;
}

.app-title {
  font-size: 3rem;
  text-align: center;
}

/* ArtistsList */

.artist-name {
  font-size: 2rem;
  padding: 5px;
  margin: 0;
  text-align: center;
  font-weight: bold;
}

.artist-genre {
  display: flex;
  padding: 5px;
  margin: 5px;
  flex-wrap: wrap;
}

.artist-genre-ind {
  font-size: 0.8rem;
  color:  var(--custom-color-brand);
  padding: 6px;
  margin: 4px;
  border-radius: 6px;
  border: 1px solid  var(--custom-color-brand);
}

.artist-container {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
}

.artist-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 200px;
  border: var(--custom-border);
  margin: 25px;
  border-radius: 20px;
}

@media screen and (max-width: 500px) {
  .artist-card {
    width: 95%;
  }
}

/* TracksList */

.tracks-name {
  font-size: 1rem;
  padding: 5px;
  margin: 0;
  text-align: center;
}

.tracks-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 240px;
  border: var(--custom-border);
  margin: 25px;
  border-radius: 20px;
}

@media screen and (max-width: 500px) {
  .tracks-card {
    width: 95%;
  }
  .tracks-year {
    padding: 2px;
  }
  .tracks-name {
    padding: 2px;
  }
}

.tracks-year {
  font-size: 0.8rem;
  padding: 5px;
  margin: 0;
  text-align: center;
  color: grey;
}

.tracks-thumbnail {
  border-radius: 10px;
  max-width: 320px;
}

.tracks-preview {
  border-radius: var(--custom-border-radius);
  border: var(--custom-border);
  margin: 10px;
  border-radius: 20px;
  max-width: 90%;
}

.tracks-artist-name {
  font-size: 1rem;
  padding: 15px 10px 5px 0px;
  margin: 0;
  text-align: center;
  font-weight: bold;
}

/* SpotifyTops */

.tops-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 100%;
}

.mode-filters {
  margin-top: 5px;
  display: flex;
  justify-content: center;
}

.time-filters {
  margin-top: 15px;
  display: flex;
  justify-content: center;
}

.time-filters-buttons {
  margin: 0 10px;
  font-size: 0.8rem;
  padding: 10px;
}

.limit-filters {
  margin-top: 15px;
  display: flex;
  justify-content: center;
}

.limit-filters-buttons {
  margin: 0 10px;
  font-size: 0.8rem;
  padding: 10px;
}

.mode-filters-buttons {
  margin: 0 10px;
  font-size: 1rem;
  padding: 20px;
}

.mode-active {
  font-weight: bold;
  color: var(--custom-color-brand);
  border-color: var(--custom-color-brand);
}

.tracks-active {
  font-weight: bold;
  color: var(--custom-color-other);
  border-color: var(--custom-color-other);
}

.limit-active {
  font-weight: bold;
  color: var(--custom-color-limit);
  border-color: var(--custom-color-limit);
}

@media only screen and (min-width: 33.75em) {
  /* 540px */
  .container {
    width: 80%;
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.artist-thumbnail {
  border-radius: 10px;
}

/* App */

section {
  display: flex;
  flex-flow: column;
  height: 100%;
}

/* FriendsList */

.friends-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 100%;
}

.friends-title {
  padding: 30px 0px 20px 0px;
  font-size: 3rem;
}

.friends-description {
  padding: 10px 0px 40px 0px;
}

.friends-map {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.friends-box {
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  width: 240px;
  margin: 10px;
}

.friends-avatar {
  width: 100%;
  border-radius: 80px;
  border: var(--custom-border);
  border-radius: var(--custom-border-radius);
}

/* Header */

.header {
  background-color: var(--custom-color-brand);
  padding: 15px 10px;
}

.header a {
  float: left;
  color: black;
  text-align: center;
  padding: 12px;
  margin-right: 12px;
  text-decoration: none;
  font-size: 18px;
  line-height: 25px;
  border-radius: 4px;
}

.header a.logo {
  font-size: 35px;
  font-weight: 700;
}

.header a.logo .white {
  color: var(--custom-color);
}

.green {
  color: var(--custom-color-brand);
}

.header a:hover {
  background-color: var(--custom-color);
  color: var(--custom-color-brand);
}

.header a.active {
  background-color: var(--custom-color);
  color: var(--custom-color-brand);
}

.header-right {
  float: right;
}

/* Loader */

.loader-container {
  display: flex;
  height: 100%;
  align-content: center;
  justify-content: center;
  align-items: center;
}

.loader {
  border: 10px solid var(--custom-bg-color);
  border-radius: 70%;
  border-top: 10px solid var(--custom-color-brand);
  width: 120px;
  height: 120px;
  -webkit-animation: spin 0.6s alternate-reverse infinite; /* Safari */
  animation: spin 0.6s alternate-reverse infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 500px) {
  .header a {
    float: none;
    display: block;
    text-align: left;
  }

  .header-right {
    float: none;
  }
}
